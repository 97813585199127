import React from 'react';

const NavigationButtons = ({ showNext, onNext, showTryAgain, onTryAgain, showSpelling, onShowSpelling }) => (
    <div>
        {showNext && <button type="button" onClick={onNext}>Next Word</button>}
        {showTryAgain && <button type="button" onClick={onTryAgain}>Try Again</button>}
        {showSpelling && <button type="button" onClick={onShowSpelling}>Show Spelling</button>}
    </div>
);


export default NavigationButtons;