import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import WordSpeaker from './WordSpeaker';
import InputControl from './InputControl';
import FeedbackDisplay from './FeedbackDisplay';
import NavigationButtons from './NavigationButtons';


const SpellingPractice = ({ selectedVoice, selectedWordList, doShuffle }) => {
    // States
    const [words, setWords] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showNextButton, setShowNextButton] = useState(false);
    const [showTryAgainButton, setTryAgainButton] = useState(false);
    const [showWordSpellingButton, setShowWordSpellingButton] = useState(false);
    const [showWordSpelling, setShowWordSpelling] = useState(false);
    const [currentWordIndex, setCurrentWordIndex] = useState(0);
    const [isWordSubmitted, setIsWordSubmitted] = useState(false);
    const [isWordCorrect, setIsWordCorrect] = useState(false);
    const [userInput, setUserInput] = useState('');
    const [feedback, setFeedback] = useState('');
    const inputRef = useRef(null);

    // Text-to-Speech function
    const speak = useCallback((text) => {
        if (!selectedVoice || !text) return;
        const synth = window.speechSynthesis;
        const utterance = new SpeechSynthesisUtterance(text);
        // setting rate (set to 1 if not set)
        utterance.rate = selectedVoice.rate || 1;
        // set language
        utterance.lang = selectedVoice.lang || 'en-AU';
        utterance.voice = selectedVoice;
        synth.speak(utterance);
    }, [selectedVoice]);


    const correctSpelling = useMemo(() => {
        return words[currentWordIndex]?.word.toLowerCase();  // Adding optional chaining for safety
    }, [words, currentWordIndex]);

    // Handle the form submission
    const handleInputSubmit = (e) => {
        e.preventDefault();
        // if user input is empty, return
        if (!userInput) return;
        console.log('input submitted with value:', userInput);
        const userAttempt = userInput.trim().toLowerCase();
        setIsWordSubmitted(true);
        if (userAttempt === correctSpelling) {
            // If the user got the word correct
            setIsWordCorrect(true);
            setFeedback(<span style={{ color: 'green', fontWeight: 'bold' }}>Correct!</span>);
            setShowNextButton(true);
            setTryAgainButton(false);
            setShowWordSpellingButton(false);
            setShowWordSpelling(false);
        } else {
            // If the user got the word wrong
            setIsWordCorrect(false);
            setFeedback(<span style={{ color: 'red', fontWeight: 'bold' }}>Incorrect. Try again!</span>);
            setShowNextButton(false);
            setTryAgainButton(true);
            setShowWordSpellingButton(true);
            setShowWordSpelling(false);
        }
        setUserInput('');
    };

    // Handle going to the next word
    const handleNextWord = useCallback(() => {
        if (currentWordIndex < words.length - 1) {
            setCurrentWordIndex(currentWordIndex + 1);
            speak(words[currentWordIndex + 1].word);  // Speak the next word in the list
        } else {
            setFeedback(
                <span style={{ color: 'green', fontWeight: 'bold' }}>
                    You have completed the word list! Select a new word list to continue practicing.
                </span>
            ); // Display a message when the user has completed the word list
            return;
        }
        resetState();
    }, [currentWordIndex, words, speak]);

    // Handle replaying the current word
    const handleReplayWord = useCallback(() => {
        console.log('replay word handled');
        // Reset the feedback and buttons
        resetState();
        // Speak the current word
        speak(words[currentWordIndex].word);
    }, [currentWordIndex, words, speak]);

    // Handle showing the word spelling
    const handleShowWordSpelling = () => {
        setShowWordSpelling(!showWordSpelling);
    };

    const resetState = () => {
        setIsWordSubmitted(false);
        setIsWordCorrect(false);
        setUserInput('');
        setFeedback('');
        setShowNextButton(false);
        setTryAgainButton(false);
        setShowWordSpellingButton(false);
        setShowWordSpelling(false);
    };

    // Fetch the word list when the selectedWordList prop changes
    useEffect(() => {
        // If no word list is selected, return
        if (selectedWordList === '') return;
        setLoading(true);
        fetch(`/word_lists/${selectedWordList}`)
            .then(response => response.json())
            .then(data => {
                setWords(data.words);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching word list:', error);
                setLoading(false);
            });
    }, [selectedWordList]);

    // Shuffle the words when the doShuffle prop changes or a new word list is selected
    useEffect(() => {
        if (doShuffle) {
            setWords(prevWords => [...prevWords].sort(() => 0.5 - Math.random()));
        }
    }, [doShuffle, selectedWordList]);

    // Focus the input field when the component mounts or the current word changes
    useEffect(() => {
        if (words.length > 0 && inputRef.current) {
            inputRef.current.focus();
        }
    }, [currentWordIndex, words.length]);

    // Handle Enter key press to submit the word
    useEffect(() => {
        const handleEnterPress = (e) => {
            if (e.key === 'Enter' && showNextButton && !showTryAgainButton && isWordSubmitted && isWordCorrect) {
                handleNextWord();
                console.log('next');
            }
        };
        document.addEventListener('keydown', handleEnterPress);
        // cleanup
        return () => {
            document.removeEventListener('keydown', handleEnterPress);
        };
    }, [showNextButton, isWordCorrect, handleNextWord, isWordSubmitted, showTryAgainButton]);

    useEffect(() => {
        // handle Enter key press to replay the word if the word is incorrect
        const handleEnterPress = (e) => {
            if (e.key === 'Enter' && showTryAgainButton && !showNextButton && isWordSubmitted && !isWordCorrect) {
                handleReplayWord();
                console.log('replay');
            }
        };
        document.addEventListener('keydown', handleEnterPress);
        // cleanup
        return () => {
            document.removeEventListener('keydown', handleEnterPress);
        };
    }, [showTryAgainButton, isWordCorrect, handleReplayWord, isWordSubmitted, showNextButton]);

    // focus the input field when the word is replayed
    useEffect(() => {
        if (words.length > 0) {
            document.getElementById('word-input').focus();
        }
    }, [currentWordIndex, words.length]);


    if (loading) {
        return <p>Select a word list from the settings to begin!</p>;
    }

    return (
        <div>
            <h2>Spelling Practice</h2>
            <p>Click the Play Word button to hear the word. Type the word you hear into the spelling box and press "Check Spelling" to see if you got it right!</p>
            <WordSpeaker word={words[currentWordIndex].word} sentence={words[currentWordIndex].sentence} speak={speak} />
            <br />
            {/* Input form for spelling the word - hide once the word is submitted */}
            {!isWordSubmitted && <InputControl userInput={userInput} onChange={(e) => setUserInput(e.target.value)} onSubmit={handleInputSubmit} />}
            <FeedbackDisplay feedback={feedback} />
            <NavigationButtons
                showNext={showNextButton}
                onNext={handleNextWord}
                showTryAgain={showTryAgainButton}
                onTryAgain={handleReplayWord}
                showSpelling={showWordSpellingButton}
                onShowSpelling={handleShowWordSpelling}
            />
            {showWordSpelling && <p>The correct spelling is: {words[currentWordIndex].word}</p>}
        </div>
    );
};

export default SpellingPractice;