import React from 'react';

const InputControl = ({ userInput, onChange, onSubmit }) => (
    <form onSubmit={onSubmit}>
        <input
            id="word-input"
            autoFocus
            type="text"
            value={userInput}
            onChange={onChange}
            placeholder="Type the word you hear"
            autoComplete="off"
        />
        <br />
        <button type="submit">Check Spelling</button>
    </form>
);

export default InputControl;