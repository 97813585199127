import React, { useState } from 'react';
import './App.css';
import VoiceSelector from './VoiceSelector';
import WordListSelector from './WordListSelector';
import SpellingPractice from './components/SpellingPractice';

function App() {
  const [showSettings, setShowSettings] = useState(false);
  const [selectedVoice, setSelectedVoice] = useState(null);
  const [selectedWordList, setSelectedWordList] = useState('');
  const [doShuffle, setDoShuffle] = useState(false);

  return (
    <div className="App">
      <header className="App-header">
        <h1>Spelling Bee Practice</h1>
      </header>
      {/* content div */}
      <div className='App-content'>
        {/* settings div*/}
        <div className='App-settings'>
          <button onClick={() => setShowSettings(!showSettings)}>
            {showSettings ? 'Hide Settings' : 'Show Settings'}
          </button>
          {showSettings && (
            <div>
              <br />
              <VoiceSelector selectedVoice={selectedVoice} setSelectedVoice={setSelectedVoice} />
              <br /><hr /><br />
              <WordListSelector selectedWordList={selectedWordList} setSelectedWordList={setSelectedWordList} setDoShuffle={setDoShuffle} />
            </div>
          )}
        </div>
        {/* spelling practice div */}
        <div className='App-spelling-practice'>
          <SpellingPractice selectedVoice={selectedVoice} selectedWordList={selectedWordList} doShuffle={doShuffle} />
        </div>
      </div>
    </div>
  );
}

export default App;
