import React, { useState, useEffect } from 'react';

function WordListSelector({ selectedWordList, setSelectedWordList, setDoShuffle }) {
    const [wordLists, setWordLists] = useState([]);

    useEffect(() => {
        fetch('/wordLists.json')
            .then(response => response.json())
            .then(data => setWordLists(data));
    }, []);

    const handleSelectionChange = (event) => {
        setSelectedWordList(event.target.value);
    };

    return (
        <div>
            <label htmlFor="word-list-selection">Select Word List: </label>
            <select id="word-list-selection" onChange={handleSelectionChange} value={selectedWordList}>
                <option value="-">-</option>
                {wordLists.map((list, index) => (
                    <option key={index} value={list.filename}>
                        {list.title}
                    </option>
                ))}
            </select>
            <br /><br />
            <label htmlFor="shuffle-checkbox">Shuffle words: </label>
            <input
                type="checkbox"
                id="shuffle-checkbox"
                onChange={(e) => setDoShuffle(e.target.checked)}
            />
        </div>
    );
}

export default WordListSelector;
