import React from 'react';

const WordSpeaker = ({ word, sentence, speak }) => {

    const handlePlayWord = () => {
        speak(word);
    };

    const handleUseInSentence = () => {
        speak(sentence);
    };

    return (
        <div className="word-speaker">
            <button type="button" onClick={handlePlayWord}>Play Word</button>
            <br /><br />
            <button type="button" onClick={handleUseInSentence}>Use Word In Sentence</button>
        </div>
    );
};

export default WordSpeaker;
