import React, { useState, useEffect } from 'react';

function VoiceSelector({ selectedVoice, setSelectedVoice }) {
  const [voices, setVoices] = useState([]);
  const [rate, setRate] = useState(1);

  useEffect(() => {
    const getVoices = () => {
      const allVoices = window.speechSynthesis.getVoices();
      const englishVoices = allVoices.filter(voice => voice.lang.startsWith('en'));
      setVoices(englishVoices);
      if (!selectedVoice && englishVoices.length > 0) {
        setSelectedVoice(englishVoices[0]);
      }
    };
    getVoices();
    if (speechSynthesis.onvoiceschanged !== undefined) {
      speechSynthesis.onvoiceschanged = getVoices;
    }
  }, [selectedVoice, setSelectedVoice]);

  const handleRateChange = (e) => {
    const newRate = parseFloat(e.target.value);
    setRate(newRate);
    if (selectedVoice) {
      selectedVoice.rate = newRate;
      setSelectedVoice(selectedVoice);
    }
  };

  const handleVoiceChange = (e) => {
    const newVoice = voices[e.target.value];
    console.log('new voice:', newVoice);
    // set the rate to the rate
    newVoice.rate = rate;    
    setSelectedVoice(newVoice);
  }


  return (
    <div>
      <label htmlFor="voice-selection">Voice: </label>
      <select
        id="voice-selection"
        onChange={handleVoiceChange}
        value={voices.indexOf(selectedVoice)}
      >
        {voices.map((voice, index) => (
          <option key={voice.voiceURI} value={index}>
            {voice.name} ({voice.lang})
          </option>
        ))}
      </select>
      <br /><br />
      <div>
        <label htmlFor="rate-slider">Voice Speed: </label>
        <input
          type="range"
          id="rate-slider"
          min="0.1"
          max="1"
          step="0.1"
          value={rate}
          onChange={handleRateChange}
        />
        <span>{rate}</span>
      </div>
    </div>
  );
}

export default VoiceSelector;
